export const answerQuestion = (section, question, value) => ({
  type: 'ANSWER_QUESTION',
  section,
  question,
  value,
})

export const goToNextQuestion = {
  type: 'GO_TO_NEXT_QUESTION',
}

export const goToPreviousQuestion = {
  type: 'GO_TO_PREVIOUS_QUESTION',
}

export const startSubjectiveTest = {
  type: 'START_SUBJECTIVE_TEST',
}
