import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { visibleQuestionCountSelector } from '../../selectors/subjectiveTest'

const useStyles = makeStyles({
  root: {
    padding: 24,
    flexGrow: 1,
  },
  text: {
    marginTop: 4,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
  },
})

const LinearDeterminate = ({ questionCount, questionsAnswered }) => {
  const classes = useStyles()
  const completed = ((questionsAnswered / questionCount) * 100).toFixed(0)

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={+completed} />
      <Typography className={classes.text}>{completed}%</Typography>
    </div>
  )
}

LinearDeterminate.propTypes = {
  completed: PropTypes.number,
}

LinearDeterminate.defaultProps = {
  completed: 50,
}

const mapStateToProps = state => ({
  questionCount: visibleQuestionCountSelector(state),
  questionsAnswered: state.subjectiveTest.questionsAnswered,
})

export default connect(
  mapStateToProps,
  null
)(LinearDeterminate)
