import React from 'react'
import PropTypes from 'prop-types'
import { XYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, LineSeries } from 'react-vis'

const LineChart = ({ data, xAxisTitle, yAxisTitle, yDomain }) => {
  return (
    <XYPlot height={300} width={300} yDomain={yDomain}>
      <VerticalGridLines />
      <HorizontalGridLines />
      <XAxis
        style={{
          line: { stroke: '#fff' },
          ticks: { stroke: '#fff' },
          text: { stroke: 'none', fill: '#fff' },
          title: { stroke: 'none', fill: '#fff' },
        }}
        tickValues={[1, 2, 3, 4]}
        title={xAxisTitle}
      />
      <YAxis
        style={{
          line: { stroke: '#fff' },
          ticks: { stroke: '#fff' },
          text: { stroke: 'none', fill: '#fff' },
          title: { stroke: 'none', fill: '#fff' },
        }}
        title={yAxisTitle}
      />
      <LineSeries data={data} />
    </XYPlot>
  )
}

LineChart.propTypes = {
  data: PropTypes.array,
  xAxisTitle: PropTypes.string,
  yAxisTitle: PropTypes.string,
  yDomain: PropTypes.array,
}

export default LineChart
