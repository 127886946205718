import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import LineChart from '../../charts/LineChart'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
  text: {
    color: '#fff',
  },
})

const CommissionLineChart = ({ commissionLineChartData }) => {
  const classes = useStyles()
  return (
    <div>
      <Typography className={classes.text}>Commission Errors</Typography>
      <Typography className={classes.text}>Total Commission Errors</Typography>
      <LineChart data={commissionLineChartData} xAxisTitle="Quarter" yAxisTitle="Errors" />
    </div>
  )
}

CommissionLineChart.propTypes = {
  commissionLineChartData: PropTypes.array,
}

export default connect(
  null,
  null
)(CommissionLineChart)
