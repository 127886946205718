export const selectAge = age => ({
  type: 'SELECT_AGE',
  age,
})

export const selectGender = genderType => ({
  type: 'SELECT_GENDER',
  genderType,
})

export const selectProfile = profileType => ({
  type: 'SELECT_PROFILE',
  profileType,
})

export const submitEmail = email => ({
  type: 'SUBMIT_EMAIL',
  email,
})
