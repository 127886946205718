import { combineReducers } from 'redux'

const ageInitialState = 0

const age = (state = ageInitialState, action) => {
  switch (action.type) {
    case 'SELECT_AGE':
      return action.age
    case 'START_OBJECTIVE_TEST':
    case 'START_SUBJECTIVE_TEST':
      return ageInitialState
    default:
      return state
  }
}

const genderInitialState = 'male'

const gender = (state = genderInitialState, action) => {
  switch (action.type) {
    case 'SELECT_GENDER':
      return action.genderType
    case 'START_OBJECTIVE_TEST':
    case 'START_SUBJECTIVE_TEST':
      return genderInitialState
    default:
      return state
  }
}

const profileInitialState = 'child'

const profile = (state = profileInitialState, action) => {
  switch (action.type) {
    case 'SELECT_PROFILE':
      return action.profileType
    case 'START_OBJECTIVE_TEST':
    case 'START_SUBJECTIVE_TEST':
      return profileInitialState
    default:
      return state
  }
}

const emailInitialState = ''

const email = (state = emailInitialState, action) => {
  switch (action.type) {
    case 'SELECT_PROFILE':
      return action.profileType
    case 'START_OBJECTIVE_TEST':
    case 'START_SUBJECTIVE_TEST':
      return emailInitialState
    default:
      return state
  }
}

export default combineReducers({ age, gender, profile, email })
