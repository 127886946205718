import React from 'react'
import { Doughnut, Chart } from 'react-chartjs-2'
import PropTypes from 'prop-types'

let originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments)

    let chart = this.chart
    let //width = chart.chart.width,
      //height = chart.chart.height,
      ctx = chart.chart.ctx

    //let oldFill = ctx.fillStyle
    ctx.restore()
    /*let fontSize = height / 95
    ctx.font = fontSize + 'em Roboto'
    ctx.textBaseline = 'middle'

    const { data } = chart.config.data.datasets[0]

    const text = data[0] + '/' + (data[0] + data[1]),
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 1.2
    ctx.fillText(text, textX, textY)
    ctx.fillStyle = oldFill*/
    ctx.save()
  },
})

const HalfDoughnut = ({ chartOptions, data }) => {
  return <Doughnut data={data} options={chartOptions} />
}

HalfDoughnut.propTypes = {
  chartOptions: PropTypes.object.isRequired,
  data: PropTypes.func.isRequired,
}

export default HalfDoughnut
