import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    //height: '100vh',
    height: 'calc(var(--vh, 1vh) * 100)',
    background: 'linear-gradient(180deg, rgba(8,47,79,1) 0%, rgba(5,19,30,1) 100%);',
    overflowY: 'scroll',
  },
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

class AppContainer extends React.Component {
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  componentDidMount() {
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
  }
  render() {
    const { children, classes } = this.props
    return <div className={classes.root}>{children}</div>
  }
}

AppContainer.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppContainer)
