import React from 'react'
import PropTypes from 'prop-types'
import ResultsGrouping from '../../components/results/ResultsGrouping'
import ContributingFactors from '../../components/results/ContributingFactors'
import ContributingFactorsBarChart from '../../components/results/ContributingFactorsBarChart'
import SurveyFooter from '../../components/SurveyFooter'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import {
  criteriaOfDysfunctionInLifeQuestionsScoreSelector,
  criteriaOfDysfunctionInLifeQuestionsQuestionCountSelector,
  hyperactiveAndImpulseSymptomsScoreSelector,
  hyperactiveAndImpulseSymptomsQuestionCountSelector,
  inattentiveSymptomsScoreSelector,
  inattentiveSymptomsQuestionCountSelector,
} from '../../selectors/subjectiveTest'
import SurveyPage from '../../components/SurveyPage'
import { Translate } from 'react-localize-redux'

const t = id => <Translate id={`components.subjectiveTest.results.${id}`} />

const useStyles = makeStyles({
  content: {
    padding: 24,
    //height: 'calc(100vh - 84px)',
    height: 'calc(var(--vh, 1vh) * 100 - 84px)',
    overflow: 'scroll',
  },
  header: {
    marginBottom: 24,
  },
  body: {
    color: '#fff',
  },
  resultsGroupingContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

const Results = ({
  criteriaOfDysfunctionInLifeQuestionsScore,
  criteriaOfDysfunctionInLifeQuestionsQuestionCount,
  hyperactiveAndImpulseSymptomsScore,
  hyperactiveAndImpulseSymptomsQuestionCount,
  inattentiveSymptomsScore,
  inattentiveSymptomsQuestionCount,
  onClick,
}) => {
  const classes = useStyles()
  return (
    <SurveyPage>
      <div className={classes.content}>
        <Typography variant="h5" color="primary" className={classes.header}>
          {t('title')}
        </Typography>
        <div className={classes.resultsGroupingContainer}>
          <ResultsGrouping
            total={inattentiveSymptomsQuestionCount}
            score={inattentiveSymptomsScore}
            title={t('inattentiveSymptoms')}
            threshold={6}
          />
          <ResultsGrouping
            total={hyperactiveAndImpulseSymptomsQuestionCount}
            score={hyperactiveAndImpulseSymptomsScore}
            title={t('hyperactiveSymptoms')}
            threshold={6}
          />
          <ResultsGrouping
            total={criteriaOfDysfunctionInLifeQuestionsQuestionCount * 3}
            score={criteriaOfDysfunctionInLifeQuestionsScore}
            title={t('criteriaOfDysfunction')}
          />
        </div>
        <ContributingFactors />
        <ContributingFactorsBarChart />
      </div>
      <SurveyFooter>
        <Button variant="contained" color="primary" size="large" onClick={onClick} fullWidth>
          {t('continue')}
        </Button>
      </SurveyFooter>
    </SurveyPage>
  )
}

Results.propTypes = {
  criteriaOfDysfunctionInLifeQuestionsScore: PropTypes.number.isRequired,
  criteriaOfDysfunctionInLifeQuestionsQuestionCount: PropTypes.number.isRequired,
  hyperactiveAndImpulseSymptomsScore: PropTypes.number.isRequired,
  hyperactiveAndImpulseSymptomsQuestionCount: PropTypes.number.isRequired,
  inattentiveSymptomsScore: PropTypes.number.isRequired,
  inattentiveSymptomsQuestionCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  criteriaOfDysfunctionInLifeQuestionsScore: criteriaOfDysfunctionInLifeQuestionsScoreSelector(
    state
  ),
  criteriaOfDysfunctionInLifeQuestionsQuestionCount: criteriaOfDysfunctionInLifeQuestionsQuestionCountSelector(
    state
  ),
  hyperactiveAndImpulseSymptomsScore: hyperactiveAndImpulseSymptomsScoreSelector(state),
  hyperactiveAndImpulseSymptomsQuestionCount: hyperactiveAndImpulseSymptomsQuestionCountSelector(
    state
  ),
  inattentiveSymptomsScore: inattentiveSymptomsScoreSelector(state),
  inattentiveSymptomsQuestionCount: inattentiveSymptomsQuestionCountSelector(state),
})

export default connect(
  mapStateToProps,
  null
)(Results)
