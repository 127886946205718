export default {
  landing: {
    adhdio: 'ADHD.io',
    adhdioDescription: 'A Multifaceted Assessment of ADHD',
    educationAndResources: {
      title: 'Education And Resources',
      description: 'Click here to learn more about ADHD',
    },
    objectiveTest: {
      title: 'Objective Measurement Test',
      description: 'This test will give you tasks that measure your attention',
    },
    subjectiveTest: {
      title: 'Subjective Measurement Test',
      description:
        'This test will ask you or the student questions to measure for symptoms of ADHD',
    },
    treatmentAndReferrals: {
      title: 'Treatment And Referrals',
      description: 'Click here to learn about treatment options and resources in your community',
    },
  },
  objectiveTest: {
    introduction: {
      title: 'ADHD.IO: Objective Measurement',
      content: 'This test will give you tasks that measure your attention',
      continue: 'Take The Test',
    },
    disclosure: {
      title: 'Disclosure',
      content:
        'The information contained in this publication should not be used as a substitute for the medical care and advice of your pediatrician. There may be variations in treatment that your pediatrician may recommend based on individual facts and circumstances.',
      continue: 'I Understand',
    },
    directions: {
      title: 'Directions',
      content:
        'This test will randomly display stop signs and go signs. If you see a stop sign, click the mouse or tap the screen as quickly as possible. If you see a go sign, ignore it.',
      continue: 'I Understand',
    },
    completed: {
      title: 'Test Completed',
      content:
        'You have completed the test. You can now view the results or send them to the appropriate parties.',
      continue: 'See Results',
    },
    results: {
      title: 'Results',
      totalWrongAnswers: 'Total Wrong Answers',
      totalWrongAnswersQuarter: 'Total Wrong Answers Quarter',
      totalWrongAnswersQuarter1: 'Total Wrong Answers Quarter 1',
      totalWrongAnswersQuarter2: 'Total Wrong Answers Quarter 2',
      totalWrongAnswersQuarter3: 'Total Wrong Answers Quarter 3',
      totalWrongAnswersQuarter4: 'Total Wrong Answers Quarter 4',
      totalOmissionErrors: 'Total Omission Errors',
      totalOmissionErrorsQuarter: 'Total Omission Errors Quarter',
      totalOmissionErrorsQuarter1: 'Total Omission Errors Quarter 1',
      totalOmissionErrorsQuarter2: 'Total Omission Errors Quarter 2',
      totalOmissionErrorsQuarter3: 'Total Omission Errors Quarter 3',
      totalOmissionErrorsQuarter4: 'Total Omission Errors Quarter 4',
      totalCommissionErrors: 'Total commission Errors',
      totalCommissionErrorsQuarter: 'Total Commission Errors Quarter',
      totalCommissionErrorsQuarter1: 'Total Commission Errors Quarter 1',
      totalCommissionErrorsQuarter2: 'Total Commission Errors Quarter 2',
      totalCommissionErrorsQuarter3: 'Total Commission Errors Quarter 3',
      totalCommissionErrorsQuarter4: 'Total Commission Errors Quarter 4',

      continue: 'Back to Home',
    },
  },
  profile: {
    gender: {
      title: 'Gender',
      content: {
        child: 'What gender are you?',
        parent: 'What gender is your child?',
        teacher: 'What gender is the child?',
      },
      continue: 'Continue',
    },
    age: {
      title: 'Age',
      content: {
        child: 'How old are you?',
        parent: 'How old is your child?',
        teacher: 'How old is the child?',
      },
      continue: 'Continue',
    },
    email: {
      title: 'Email',
      content: 'Submit your email to see your results and have them sent to you',
      continue: 'Continue',
    },
  },
  subjectiveTest: {
    introduction: {
      title: 'ADHD.IO: Subjective Measurement',
      content: 'This test is intended to assist parents and students in the diagnosis of ADHD',
      continue: 'Take The Test',
    },
    profile: {
      title: 'Profile',
      content: 'Who is taking this test?',
      continue: 'Continue',
    },
    disclosure: {
      title: 'Disclosure',
      content:
        'Each rating should be considered in the context of what is appropriate or not for you or your child. So if it is normal for a particular behavior to happen don’t rate it as a problem. This application should not be used as a substitute for the medical decision making or diagnosis of ADHD by your physician.  This test is meant to help identify symptoms and dysfunction which may suggest ADHD and prompt further evaluation with your physician.',
      continue: 'I Understand',
    },
    directions: {
      title: 'Directions',
      content: {
        child: 'When completing this form, please think about your behavior in the past 6 months.',
        parent: `When completing this form, please think about your child's behavior in the past 6 months.`,
        teacher: `When completing this form, please think about the child's behavior in the past 6 months.`,
      },
      continue: 'I Understand',
    },
    test: {
      previous: 'Previous',
      next: 'Next',
      finish: 'Finish',
    },
    completed: {
      title: 'Congratulations!',
      content:
        'You have completed the test. You can now view the results or send them to the appropriate parties.',
      continue: 'See Results',
    },
    results: {
      title: 'Results',
      inattentiveSymptoms: 'Inattentive Symptoms',
      hyperactiveSymptoms: 'Hyperactive and Impulsive Symptoms',
      criteriaOfDysfunction: 'Criteria Of Dysfunction In Life Symptoms',
      contributingFactors: 'Contributing Factors',
      none: 'None',
      continue: 'Back to Home',
    },
  },
}
