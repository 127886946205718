import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import store from './redux/store'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import mui from './components/Theme/mui'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

import { en } from './i18n'
import { renderToStaticMarkup } from 'react-dom/server'
import { LocalizeProvider, initialize, addTranslationForLanguage } from 'react-localize-redux'

const muiTheme = createMuiTheme(mui)

const languages = [{ name: 'English', code: 'en' }]
store.dispatch(initialize({ languages, options: { renderToStaticMarkup } }))
store.dispatch(addTranslationForLanguage(en, 'en'))

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <App />
      </MuiThemeProvider>
    </LocalizeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
