export default {
  palette: {
    primary: {
      main: '#587e98',
    },
    secondary: {
      main: '#587e98',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
}
