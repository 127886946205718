import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import SurveyFooter from '../components/SurveyFooter'
import SurveyPage from '../components/SurveyPage'
import PageHeader from '../components/PageHeader'
import AnswerRadioGroup from '../components/AnswerRadioGroup'
import { selectGender } from '../redux/actions/profile'
import { Translate } from 'react-localize-redux'

const t = id => <Translate id={`components.profile.gender.${id}`} />

const Gender = ({ onClick, profile, selectGender }) => {
  const handleChange = genderType => {
    selectGender(genderType)
  }
  return (
    <SurveyPage>
      <PageHeader header={t('title')} body={t('content.' + profile)}>
        <AnswerRadioGroup type={'gender'} onChange={handleChange} />
      </PageHeader>
      <SurveyFooter>
        <Button variant="contained" color="primary" size="large" onClick={onClick} fullWidth>
          {t('continue')}
        </Button>
      </SurveyFooter>
    </SurveyPage>
  )
}

Gender.propTypes = {
  onClick: PropTypes.func.isRequired,
  profile: PropTypes.string.isRequired,
  selectGender: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
})

const mapDispatchToProps = dispatch => ({
  selectGender: genderType => dispatch(selectGender(genderType)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gender)
