import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    zIndex: 0,
  },
}

class SpeedometerDial extends Component {
  state = {
    step: -1,
  }

  tick = () => {
    this.setState(state => {
      return {
        step: Math.min(100, state.step + 3),
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { canvas } = this.refs
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    const { score, total } = this.props

    const angle = Math.PI * (score / total) * (this.state.step / 100)

    const startX = 152
    const startY = 152

    const baseAngle = angle + Math.PI / 2

    const baseX = Math.cos(baseAngle) * 5
    const baseY = Math.sin(baseAngle) * 5

    const endX = Math.cos(angle) * 120
    const endY = Math.sin(angle) * 120

    ctx.beginPath()
    ctx.moveTo(startX - 0.5, startY - 0.5)
    ctx.lineTo(startX - baseX - 0.5, startY - baseY - 0.5)
    ctx.lineTo(startX - endX - 0.5, startY - endY - 0.5)
    ctx.lineTo(startX + baseX - 0.5, startY + baseY - 0.5)
    ctx.lineTo(startX - 0.5, startY - 0.5)
    ctx.closePath()
    ctx.fillStyle = '#cdfdfd'
    ctx.fill()

    if (this.state.step < 100) {
      requestAnimationFrame(this.tick)
      /*setTimeout(() => {
        this.setState({
          step: this.state.step + 1,
        })
      }, 1)*/
    }
  }

  componentDidMount() {
    this.setState({
      step: 0,
    })
  }
  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <canvas ref="canvas" width={304} height={200} />
      </div>
    )
  }
}

SpeedometerDial.propTypes = {
  classes: PropTypes.object.isRequired,
  score: PropTypes.number,
  total: PropTypes.number,
}

export default withStyles(styles)(SpeedometerDial)
