import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import SurveyFooter from '../components/SurveyFooter'
import SurveyPage from '../components/SurveyPage'
import PageHeader from '../components/PageHeader'
import TextField from '@material-ui/core/TextField'
import { selectAge } from '../redux/actions/profile'
import { Translate } from 'react-localize-redux'

const t = id => <Translate id={`components.profile.age.${id}`} />

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField)

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  label: {
    color: 'white',
  },
}))

const Age = ({ onClick, profile, selectAge }) => {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    age: '',
  })

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
    selectAge(event.target.value)
  }

  return (
    <SurveyPage>
      <PageHeader header={t('title')} body={t('content.' + profile)}>
        <form className={classes.container} noValidate autoComplete="off">
          <CssTextField
            id="outlined-number"
            label={t('title')}
            value={values.age}
            onChange={handleChange('age')}
            type="number"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.label,
              },
            }}
            margin="normal"
            variant="outlined"
            inputProps={{ style: { color: 'white' } }}
            /*
            InputProps={{
                classes: {
                  inputColor: classes.inputColor
                }
              }}
              */
          />
        </form>
      </PageHeader>
      <SurveyFooter>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onClick}
          fullWidth
          disabled={!values.age}>
          {t('continue')}
        </Button>
      </SurveyFooter>
    </SurveyPage>
  )
}

Age.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectAge: PropTypes.func,
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
})

const mapDispatchToProps = dispatch => ({
  selectAge: age => dispatch(selectAge(age)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Age)
