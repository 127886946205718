import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Translate } from 'react-localize-redux'

const t = id => <Translate id={`components.landing.${id}`} />

const useStyles = makeStyles({
  root: {
    padding: '48px 20px',
  },
  section: {
    display: 'flex',
    padding: '20px 0',
    justifyContent: 'space-between',
  },
  button: {
    marginRight: 20,
    minWidth: 160,
  },
  description: {
    color: 'white',
    textAlign: 'right',
  },
})

const Landing = ({
  onEducationAndResourcesClick,
  onObjectiveTestClick,
  onSubjectiveTestClick,
  onTreatmentAndReferralsClick,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant="h2" color="primary">
        {t('adhdio')}
      </Typography>
      <Typography variant="subtitle1" style={{ color: 'white' }}>
        {t('adhdioDescription')}
      </Typography>

      <div className={classes.section}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubjectiveTestClick}
          className={classes.button}>
          {t('subjectiveTest.title')}
        </Button>
        <Typography className={classes.description}>{t('subjectiveTest.description')}</Typography>
      </div>

      <div className={classes.section}>
        <Button
          variant="contained"
          color="primary"
          onClick={onObjectiveTestClick}
          className={classes.button}>
          {t('objectiveTest.title')}
        </Button>
        <Typography className={classes.description}>{t('objectiveTest.description')}</Typography>
      </div>

      <div className={classes.section}>
        <Button
          variant="contained"
          color="primary"
          onClick={onEducationAndResourcesClick}
          className={classes.button}>
          {t('educationAndResources.title')}
        </Button>
        <Typography className={classes.description}>
          {t('educationAndResources.description')}
        </Typography>
      </div>

      <div className={classes.section}>
        <Button
          variant="contained"
          color="primary"
          onClick={onTreatmentAndReferralsClick}
          className={classes.button}>
          {t('treatmentAndReferrals.title')}
        </Button>
        <Typography className={classes.description}>
          {t('treatmentAndReferrals.description')}
        </Typography>
      </div>
    </div>
  )
}

Landing.propTypes = {
  onEducationAndResourcesClick: PropTypes.func.isRequired,
  onObjectiveTestClick: PropTypes.func.isRequired,
  onSubjectiveTestClick: PropTypes.func.isRequired,
  onTreatmentAndReferralsClick: PropTypes.func.isRequired,
}

export default Landing
