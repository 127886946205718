import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    height: 'auto',
    flex: 1,
    width: 'calc(100% - 20px)',
    position: 'absolute',
    bottom: 0,
    margin: 10,
  },
})

const SurveyFooter = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

SurveyFooter.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SurveyFooter
