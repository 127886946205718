import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const useStyles = makeStyles({
  root: {
    margin: 24,
    position: 'absolute',
    top: '50%',
  },
  header: {
    marginBottom: 24,
  },
  body: {
    color: '#fff',
  },
})

const PageHeader = ({ body, children, header }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <ReactCSSTransitionGroup
        transitionName="page-header-header"
        transitionAppear={true}
        transitionAppearTimeout={400}
        transitionEnter={false}
        transitionLeave={false}>
        <Typography variant="h5" color="primary" className={classes.header}>
          {header}
        </Typography>
      </ReactCSSTransitionGroup>
      <ReactCSSTransitionGroup
        transitionName="page-header-body"
        transitionAppear={true}
        transitionAppearTimeout={800}
        transitionEnter={false}
        transitionLeave={false}>
        <Typography variant="body2" className={classes.body}>
          {body}
        </Typography>
        {children}
      </ReactCSSTransitionGroup>
    </div>
  )
}

PageHeader.propTypes = {
  // translation object
  body: PropTypes.object,
  children: PropTypes.node,
  // translation object
  header: PropTypes.object,
}

export default PageHeader
