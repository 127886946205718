// @todo will this be necessary?
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {},
})

const SurveyPage = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

SurveyPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SurveyPage
