import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clickScreen, displayImage } from '../../redux/actions/objectiveTest'

const IMAGE_SHOWN_TIME = 150
const NEXT_IMAGE_SHOWN_TIME = 1875 - IMAGE_SHOWN_TIME

class TimedTest extends Component {
  state = {
    startTime: Date.now(),
    imageClicked: false,
    imagesShown: 0,
    // @todo hard coded for now
    imagesToShow: 16,
  }
  componentDidMount() {
    window.addEventListener('resize', this.resizeCanvas, false)
    this.resizeCanvas()

    // end the test after the test duration
    setTimeout(() => {
      this.onComplete()
    }, this.props.testLength + this.props.startDelay)

    // start the test 2 seconds in
    setTimeout(() => {
      this.drawImage()
    }, this.props.startDelay)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeCanvas, false)
  }
  resizeCanvas = e => {
    const { canvas } = this.refs
    canvas.width = document.documentElement.clientWidth
    canvas.height = document.documentElement.clientHeight
  }
  onComplete = () => {
    this.props.onCompleted()
  }
  drawImage = () => {
    const rng = Math.random()
    if (rng <= 0.5) {
      this.drawGoCircle()
    } else {
      this.drawStopSign()
    }
    this.setState(state => ({
      imagesShown: state.imagesShown + 1,
    }))

    setTimeout(() => {
      this.clearCanvas()
    }, IMAGE_SHOWN_TIME)

    if (this.state.imagesShown === this.state.imagesToShow) {
      return
    }

    setTimeout(() => {
      this.drawImage()
    }, NEXT_IMAGE_SHOWN_TIME /*Math.random() * 1000 + 1500*/)
  }
  clearCanvas() {
    const { canvas } = this.refs
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }
  drawGoCircle() {
    const { canvas } = this.refs
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    var centerX = canvas.width / 2
    var centerY = canvas.height / 2
    var radius = 70

    ctx.beginPath()
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false)
    ctx.fillStyle = 'gray'
    ctx.fill()
    ctx.lineWidth = 5
    ctx.strokeStyle = 'black'
    ctx.closePath()
    ctx.stroke()
    this.setState(state => ({
      currentImageClicked: false,
    }))
    this.props.displayImage('green', Date.now())
  }
  drawStopSign = () => {
    const { canvas } = this.refs
    const ctx = canvas.getContext('2d')

    const numberOfSides = 8
    const centerX = canvas.width / 2
    const centerY = canvas.height / 2
    const radius = 70
    const offset = Math.PI / 8

    ctx.beginPath()
    ctx.moveTo(centerX + radius * Math.cos(offset), centerY + radius * Math.sin(offset))
    for (var i = 1; i <= numberOfSides; i += 1) {
      ctx.lineTo(
        centerX + radius * Math.cos((i * 2 * Math.PI) / numberOfSides + offset),
        centerY + radius * Math.sin((i * 2 * Math.PI) / numberOfSides + offset)
      )
    }
    ctx.closePath()

    ctx.fillStyle = 'gray'
    ctx.fill()
    ctx.lineWidth = 5
    ctx.strokeStyle = 'black'
    ctx.stroke()

    this.setState(state => ({
      currentImageClicked: false,
    }))
    this.props.displayImage('red', Date.now())
  }
  handleClick = () => {
    if (!this.state.currentImageClicked) {
      this.setState(state => ({
        currentImageClicked: true,
      }))
      this.props.clickScreen(Date.now())
    }
  }
  render() {
    return (
      <canvas
        ref="canvas"
        //width={300}
        //height={300}
        onClick={this.handleClick}
      />
    )
  }
}

TimedTest.propTypes = {
  clickScreen: PropTypes.func,
  displayImage: PropTypes.func,
  onCompleted: PropTypes.func,
  startDelay: PropTypes.number,
  testLength: PropTypes.number,
}

const mapStateToProps = state => ({
  startDelay: state.objectiveTest.data.startDelay,
  testLength: state.objectiveTest.data.testLength,
})

const mapDispatchToProps = dispatch => ({
  clickScreen: timestamp => dispatch(clickScreen(timestamp)),
  displayImage: (shape, timestamp) => dispatch(displayImage(shape, timestamp)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimedTest)
