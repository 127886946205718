import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import {
  contributingFactorCountSelector,
  contributingIssuesQuestionsSelector,
} from '../../selectors/subjectiveTest'
import FrequencyFactor from './FrequencyFactor'
import SubjectFactor from './SubjectFactor'
import { Translate } from 'react-localize-redux'

const t = id => <Translate id={`components.subjectiveTest.results.${id}`} />

const useStyles = makeStyles({
  container: {
    margin: '8px 2px',
    padding: 8,
    background: '#1c2b34',
    color: '#fff',
  },
  contributingFactor: {
    height: 50,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    margin: '10px 0',
    borderRadius: 4,
  },
  specificSubjectStruggles: {
    background: 'orange',
  },
})

export const ContributingFactors = ({ contributingFactorCount, questions }) => {
  const classes = useStyles()
  return (
    <Card elevation={2} className={classes.container}>
      <Typography variant="h6">{t('contributingFactors')}</Typography>
      {/*<Typography
        className={clsx(classes.contributingFactor, classes.specificSubjectStruggles)}
        variant="subtitle1">
        Specific Subject Struggles
      </Typography>*/}
      {contributingFactorCount ? (
        questions.map((question, i) => {
          return question.type === 'frequency' ? (
            <FrequencyFactor question={question} classes={classes} />
          ) : (
            <SubjectFactor question={question} classes={classes} />
          )
        })
      ) : (
        <Typography>{t('none')}</Typography>
      )}
    </Card>
  )
}

const mapStateToProps = state => ({
  questions: contributingIssuesQuestionsSelector(state),
  contributingFactorCount: contributingFactorCountSelector(state),
})

export default connect(
  mapStateToProps,
  null
)(ContributingFactors)
