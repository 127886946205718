import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const SubjectFactor = ({ classes, question }) => {
  return (
    question.value !== 'no' && (
      <Typography
        className={classes.contributingFactor}
        style={{ backgroundColor: question.backgroundColor }}
        variant="subtitle1">
        {question.factorName}
      </Typography>
    )
  )
}

SubjectFactor.propTypes = {
  classes: PropTypes.object.isRequired,
  question: PropTypes.object,
}

export default SubjectFactor
