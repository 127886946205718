import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import {
  frequencyRadioGroup,
  genderRadioGroup,
  profileRadioGroup,
  subjectsRadioGroup,
  yesNoRadioGroup,
} from '../data/answers'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: 24,
    marginLeft: theme.spacing(1),
    marginRight: -16,
    display: 'flex',
    flexGrow: 1,
  },
  formControlLabel: {
    transition: 'all .2s',
    color: '#fff',
  },
  formControlLabelTop: {
    border: '1px solid lightGray',
  },
  formControlLabelRest: {
    borderRight: '1px solid lightGray',
    borderBottom: '1px solid lightGray',
    borderLeft: '1px solid lightGray',
  },
  selected: {
    backgroundColor: 'rgba(76,124,183,.1)',
  },
}))

const AnswerRadioGroup = ({ onChange, selectedValue, type }) => {
  const classes = useStyles()
  let radioGroup
  if (type === 'frequency') {
    radioGroup = frequencyRadioGroup
  } else if (type === 'subjects') {
    radioGroup = subjectsRadioGroup
  } else if (type === 'yesNo') {
    radioGroup = yesNoRadioGroup
  } else if (type === 'profile') {
    radioGroup = profileRadioGroup
  } else if (type === 'gender') {
    radioGroup = genderRadioGroup
  }
  const [value, setValue] = React.useState(selectedValue || radioGroup.items[0].value)

  function handleChange(event) {
    setValue(event.target.value)
    onChange(event.target.value)
  }

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <RadioGroup
        aria-label={radioGroup.name}
        name={radioGroup.name}
        value={value}
        onChange={handleChange}>
        {radioGroup.items.map((item, i) => (
          <FormControlLabel
            key={i}
            className={clsx(
              classes.formControlLabel,
              !i ? classes.formControlLabelTop : classes.formControlLabelRest,
              value === item.value && classes.selected
            )}
            value={item.value}
            control={<Radio style={{ color: value !== item.value ? '#fff' : '' }} />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

AnswerRadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  type: PropTypes.string.isRequired,
}

export default AnswerRadioGroup
