import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SurveyFooter from '../../components/SurveyFooter'
import { Button } from '@material-ui/core'
import SurveyPage from '../../components/SurveyPage'
import PageHeader from '../../components/PageHeader'
import AnswerRadioGroup from '../../components/AnswerRadioGroup'
import { selectProfile } from '../../redux/actions/profile'
import { Translate } from 'react-localize-redux'

const t = id => <Translate id={`components.subjectiveTest.profile.${id}`} />

const Profile = ({ onClick, selectProfile }) => {
  const handleChange = profileType => {
    selectProfile(profileType)
  }
  return (
    <SurveyPage>
      <PageHeader header={t('title')} body={t('content')}>
        <AnswerRadioGroup type={'profile'} onChange={handleChange} />
      </PageHeader>
      <SurveyFooter>
        <Button variant="contained" color="primary" size="large" onClick={onClick} fullWidth>
          {t('continue')}
        </Button>
      </SurveyFooter>
    </SurveyPage>
  )
}

Profile.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectProfile: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  selectProfile: profileType => dispatch(selectProfile(profileType)),
})

export default connect(
  null,
  mapDispatchToProps
)(Profile)
