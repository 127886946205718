import { createSelector } from 'reselect'
import { profileSelector } from './profile'
import { reduce } from 'lodash'

const binaryThresholdFilter = questions =>
  questions.filter(q => q.value === 'often' || q.value === 'mostOfTheTime').length

const scaleThresholdFilter = questions =>
  questions.reduce((sum, question) => {
    if (question.value === 'mostOfTheTime') {
      sum += 3
    } else if (question.value === 'often') {
      sum += 2
    } else if (question.value === 'sometimes') {
      sum += 1
    }
    return sum
  }, 0)

const sectionsSelector = state => state.subjectiveTest.sections

// based on certain criteria, we will only want to show certain questions
export const visibleSectionsSelector = createSelector(
  profileSelector,
  sectionsSelector,
  (profile, sections) =>
    sections.map(section => ({
      ...section,
      questions: section.questions.filter(
        ({ filter }) => !filter || profile[filter[0]] > filter[1]
      ),
    }))
)

export const criteriaOfDysfunctionInLifeQuestionsScoreSelector = createSelector(
  visibleSectionsSelector,
  sections => scaleThresholdFilter(sections[3].questions)
)

export const criteriaOfDysfunctionInLifeQuestionsQuestionCountSelector = createSelector(
  visibleSectionsSelector,
  sections => sections[3].questions.length
)

export const hyperactiveAndImpulseSymptomsScoreSelector = createSelector(
  visibleSectionsSelector,
  sections => binaryThresholdFilter(sections[1].questions)
)

export const hyperactiveAndImpulseSymptomsQuestionCountSelector = createSelector(
  visibleSectionsSelector,
  sections => sections[1].questions.length
)

export const inattentiveSymptomsScoreSelector = createSelector(
  visibleSectionsSelector,
  sections => binaryThresholdFilter(sections[0].questions)
)

export const inattentiveSymptomsQuestionCountSelector = createSelector(
  visibleSectionsSelector,
  sections => sections[0].questions.length
)

export const contributingIssuesQuestionsSelector = createSelector(
  visibleSectionsSelector,
  sections => sections[4].questions
)

export const contributingFactorCountSelector = createSelector(
  contributingIssuesQuestionsSelector,
  contributingIssuesQuestions => binaryThresholdFilter(contributingIssuesQuestions)
)

export const visibleQuestionCountSelector = createSelector(
  visibleSectionsSelector,
  visibleSections => {
    return reduce(
      visibleSections,
      (sum, section) => {
        return sum + section.questions.length
      },
      0
    )
  }
)
