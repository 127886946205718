import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import HalfDoughnut from '../charts/HalfDoughnut'
import SpeedometerDial from '../charts/SpeedometerDial'

const chartOptions = {
  responsive: true,
  legend: {
    display: false,
  },
  cutoutPercentage: 78,
  rotation: 1 * Math.PI,
  circumference: 1 * Math.PI,
  elements: {
    arc: {
      borderWidth: 0,
      hoverBorderWidth: 2,
    },
  },
  tooltips: {
    enabled: false,
  },
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
})

const ResultDoughnut = ({ score, total }) => {
  const classes = useStyles()
  const chartData = canvas => {
    const ctx = canvas.getContext('2d')
    let grd = ctx.createLinearGradient(0, 0, 304, 0)
    grd.addColorStop(0, 'green')
    grd.addColorStop(0.33, 'yellow')
    grd.addColorStop(0.66, 'orange')
    grd.addColorStop(1, 'red')
    return {
      labels: ['Positive', 'Negative'],
      datasets: [
        {
          data: [score, total - score],
          backgroundColor: [grd, '#cdfdfd'],
          hoverBackgroundColor: [grd, '#cdfdfd'],
        },
      ],
    }
  }
  return (
    <div className={classes.container}>
      <HalfDoughnut data={chartData} chartOptions={chartOptions} />
      <SpeedometerDial score={score} total={total} />
    </div>
  )
}

ResultDoughnut.propTypes = {
  score: PropTypes.number,
  total: PropTypes.number,
}

export default ResultDoughnut
