import { createSelector } from 'reselect'

const imagesSelector = state => state.objectiveTest.images
const dataSelector = state => state.objectiveTest.data

const isCommissionError = image => {
  return image.shape === 'green' && image.clickedTimestamp
}

const isOmissionError = image => {
  return image.shape === 'red' && !image.clickedTimestamp
}

const isCorrectAnswer = image => {
  return !isCommissionError(image) && !isOmissionError(image)
}

const getQuarterIndex = (image, data) => {
  const quarter = data.testLength / 4
  if (image.shownTimestamp < data.startTimestamp + quarter) {
    return 0
  } else if (image.shownTimestamp < data.startTimestamp + 2 * quarter) {
    return 1
  } else if (image.shownTimestamp < data.startTimestamp + 3 * quarter) {
    return 2
  } else if (image.shownTimestamp < data.startTimestamp + 4 * quarter) {
    return 3
  }
}

const getXAxisByQuarter = (timestamp, data) =>
  (timestamp - (data.startTimestamp + data.startDelay)) / (data.testLength / 4)

export const totalQuestionsSelector = createSelector(
  imagesSelector,
  images => images.length
)

export const totalQuestionsPerQuarterSelector = createSelector(
  imagesSelector,
  dataSelector,
  (images, data) => {
    return images.reduce(
      (arr, image) => {
        const quarterIndex = getQuarterIndex(image, data)
        arr[quarterIndex] += 1
        return arr
      },
      [0, 0, 0, 0]
    )
  }
)

export const totalCorrectAnswersSelector = createSelector(
  imagesSelector,
  images =>
    images.reduce((total, image) => {
      if (isCorrectAnswer(image)) {
        total = total + 1
      }
      return total
    }, 0)
)

export const totalCorrectAnswersPerQuarterSelector = createSelector(
  imagesSelector,
  dataSelector,
  (images, data) => {
    return images.reduce(
      (arr, image) => {
        const quarterIndex = getQuarterIndex(image, data)
        if (isCorrectAnswer(image)) {
          arr[quarterIndex] += 1
        }
        return arr
      },
      [0, 0, 0, 0]
    )
  }
)

export const totalIncorrectAnswersSelector = createSelector(
  totalQuestionsSelector,
  totalCorrectAnswersSelector,
  (totalQuestions, totalCorrectAnswers) => totalQuestions - totalCorrectAnswers
)

export const totalIncorrectAnswersPerQuarterSelector = createSelector(
  totalQuestionsPerQuarterSelector,
  totalCorrectAnswersPerQuarterSelector,
  (totalQuestionsPerQuarter, totalCorrectAnswersPerQuarter) => {
    const arr = []
    for (let i = 0; i < 4; i++) {
      arr[i] = totalQuestionsPerQuarter[i] - totalCorrectAnswersPerQuarter[i]
    }
    return arr
  }
)

export const omissionErrorsSelector = createSelector(
  imagesSelector,
  images => images.filter(image => isOmissionError(image))
)

export const totalOmissionErrorsSelector = createSelector(
  imagesSelector,
  images =>
    images.reduce((total, image) => {
      if (isOmissionError(image)) {
        total += 1
      }
      return total
    }, 0)
)

export const totalOmissionErrorsPerQuarterSelector = createSelector(
  imagesSelector,
  dataSelector,
  (images, data) => {
    return images.reduce(
      (arr, image) => {
        const quarterIndex = getQuarterIndex(image, data)
        if (isOmissionError(image)) {
          arr[quarterIndex] += 1
        }
        return arr
      },
      [0, 0, 0, 0]
    )
  }
)

export const omissionLineChartDataSelector = createSelector(
  omissionErrorsSelector,
  dataSelector,
  (omissionErrors, data) => {
    return [
      {
        x: 0,
        y: 0,
      },
    ].concat(
      omissionErrors
        .map((error, i) => ({
          x: getXAxisByQuarter(error.shownTimestamp, data),
          y: i + 1,
        }))
        .concat({
          x: 4,
          y: omissionErrors.length,
        })
    )
  }
)

export const commissionErrorsSelector = createSelector(
  imagesSelector,
  images => images.filter(image => isCommissionError(image))
)

export const totalCommissionErrorsSelector = createSelector(
  imagesSelector,
  images =>
    images.reduce((total, image) => {
      if (isCommissionError(image)) {
        total += 1
      }
      return total
    }, 0)
)

export const totalCommissionErrorsPerQuarterSelector = createSelector(
  imagesSelector,
  dataSelector,
  (images, data) => {
    return images.reduce(
      (arr, image) => {
        const quarterIndex = getQuarterIndex(image, data)
        if (isCommissionError(image)) {
          arr[quarterIndex] += 1
        }
        return arr
      },
      [0, 0, 0, 0]
    )
  }
)

export const commissionLineChartDataSelector = createSelector(
  commissionErrorsSelector,
  dataSelector,
  (commissionErrors, data) => {
    return [
      {
        x: 0,
        y: 0,
      },
    ].concat(
      commissionErrors
        .map((error, i) => ({
          x: getXAxisByQuarter(error.clickedTimestamp, data),
          y: i + 1,
        }))
        .concat({
          x: 4,
          y: commissionErrors.length,
        })
    )
  }
)

export const responseTimeLineChartDataSelector = createSelector(
  imagesSelector,
  dataSelector,
  (images, data) => {
    return [
      {
        x: 0,
        y: 0,
      },
    ].concat(
      images
        .filter(image => image.clickedTimestamp)
        .map(image => ({
          x: getXAxisByQuarter(image.clickedTimestamp, data),
          y: (image.clickedTimestamp - image.shownTimestamp) / 1000,
        }))
        .concat({
          x: 4,
          y: 0,
        })
    )
  }
)
