import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const Threshold = ({ score, threshold }) => {
  return threshold ? (
    score >= threshold ? (
      <Typography variant="subtitle1">Suggestive</Typography>
    ) : (
      <Typography variant="subtitle1">Not Suggestive</Typography>
    )
  ) : null
}

Threshold.propTypes = {
  score: PropTypes.number.isRequired,
  threshold: PropTypes.number,
}

export default Threshold
