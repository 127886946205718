import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { contributingIssuesQuestionsSelector } from '../../selectors/subjectiveTest'

const useStyles = makeStyles({
  container: {
    margin: '8px 2px',
    padding: 8,
    background: '#1c2b34',
    color: '#fff',
  },
  chart: {
    display: 'flex',
  },
  chartKey: {
    width: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  chartContent: {
    display: 'flex',
  },
  bar: {
    height: 200,
    width: 18,
    margin: 4,
    position: 'relative',
    borderRadius: 4,
  },
  barContent: {
    position: 'relative',
  },
  contributingFactor: {
    color: 'white',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    width: 18,
    position: 'absolute',
    bottom: 15,
    transform: 'rotate(-90deg)',
  },
})

const max = 200

export const ContributingFactorsBarChart = ({ questions }) => {
  const classes = useStyles()

  const getHeight = value => {
    if (value === 'mostOfTheTime') {
      return max
    } else if (value === 'often') {
      return max * 0.66
    } else if (value === 'sometimes') {
      return max * 0.33
    } else {
      return 0
    }
  }

  return (
    <Card elevation={2} className={classes.container}>
      <Typography variant="h6">{'Areas of Dysfunction'}</Typography>
      <div className={classes.chart}>
        <div className={classes.chartKey}>
          <div>Most of the Time</div>
          <div>Often</div>
          <div>Sometimes</div>
          <div>Never</div>
        </div>
        <div className={classes.chartContent}>
          {questions.map((question, i) => {
            return i > 0 ? (
              <div
                key={i}
                className={classes.bar}
                style={{
                  border: `1px solid ${question.backgroundColor}`,
                }}>
                <div
                  className={classes.barContent}
                  style={{
                    backgroundColor: question.backgroundColor,
                    height: getHeight(question.value),
                    top: max - getHeight(question.value),
                  }}>
                  <Typography className={classes.contributingFactor} variant="subtitle1">
                    {question.factorName}
                  </Typography>
                </div>
              </div>
            ) : null
          })}
        </div>
      </div>
    </Card>
  )
}

ContributingFactorsBarChart.propTypes = {
  questions: PropTypes.array,
  contributingFactorCount: PropTypes.number,
}

const mapStateToProps = state => ({
  questions: contributingIssuesQuestionsSelector(state),
})

export default connect(
  mapStateToProps,
  null
)(ContributingFactorsBarChart)
