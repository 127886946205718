import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import ResultDoughnut from './ResultDoughnut'
import Severity from './Severity'
import Threshold from './Threshold'

const useStyles = makeStyles({
  container: {
    margin: '8px 2px',
    padding: 8,
    background: '#1c2b34',
    color: '#fff',
  },
  halfDoughnutContainer: {
    width: 304,
  },
})

const ResultsGrouping = ({ score, threshold, title, total }) => {
  const classes = useStyles()
  return (
    <Card elevation={2} className={classes.container}>
      <Typography variant="h6">{title}</Typography>
      <div className={classes.halfDoughnutContainer}>
        <ResultDoughnut score={score} total={total} />
      </div>
      {threshold ? (
        <Threshold score={score} threshold={threshold} />
      ) : (
        <Severity score={score} total={total} />
      )}
    </Card>
  )
}

ResultsGrouping.propTypes = {
  total: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  threshold: PropTypes.number,
}

export default ResultsGrouping
