import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import LineChart from '../../charts/LineChart'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
  text: {
    color: '#fff',
  },
})

const ResponseTimeLineChart = ({ responseTimeLineChartData }) => {
  const classes = useStyles()
  return (
    <div>
      <Typography className={classes.text}>Response Time</Typography>
      <Typography className={classes.text}>Overall Response Time</Typography>

      <LineChart
        data={responseTimeLineChartData}
        xAxisTitle="Quarter"
        yAxisTitle="Response Time/secs"
        yDomain={[0, 2]}
      />
    </div>
  )
}

ResponseTimeLineChart.propTypes = {
  responseTimeLineChartData: PropTypes.array,
}

export default connect(
  null,
  null
)(ResponseTimeLineChart)
