import React from 'react'
import PropTypes from 'prop-types'
import './App.css'
import '../node_modules/react-vis/dist/style.css'
import { connect } from 'react-redux'
import Landing from './pages/Landing'
import ObjectiveTest from './pages/ObjectiveTest/ObjectiveTest'
import SubjectiveTest from './pages/SubjectiveTest/SubjectiveTest'
import { startSubjectiveTest } from './redux/actions/subjectiveTest'
import AppContainer from './components/AppContainer'

class App extends React.Component {
  state = {
    showLanding: true,
    showSubjectiveTest: false,
    showObjectiveTest: false,
  }

  handleEducationAndResourcesClick = () => {}
  handleObjectiveTestClick = () => {
    this.setState({
      showLanding: false,
      showSubjectiveTest: false,
      showObjectiveTest: true,
    })
  }
  handleSubjectiveTestClick = () => {
    this.setState({
      showLanding: false,
      showSubjectiveTest: true,
      showObjectiveTest: false,
    })
    this.props.startSubjectiveTest()
  }
  handleTreatmentAndReferralsClick = () => {}

  handleReturnToHomeClick = () => {
    this.setState({
      showLanding: true,
      showSubjectiveTest: false,
      showObjectiveTest: false,
    })
  }
  render() {
    const { showLanding, showSubjectiveTest } = this.state

    let content
    if (showLanding) {
      content = (
        <Landing
          onEducationAndResourcesClick={this.handleEducationAndResourcesClick}
          onObjectiveTestClick={this.handleObjectiveTestClick}
          onSubjectiveTestClick={this.handleSubjectiveTestClick}
          onTreatmentAndReferralsClick={this.handleTreatmentAndReferralsClick}
        />
      )
    } else if (showSubjectiveTest) {
      content = <SubjectiveTest onReturnHomeClick={this.handleReturnToHomeClick} />
    } else {
      content = <ObjectiveTest onReturnHomeClick={this.handleReturnToHomeClick} />
    }

    return <AppContainer>{content}</AppContainer>
  }
}

App.propTypes = {
  startSubjectiveTest: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  startSubjectiveTest: () => dispatch(startSubjectiveTest),
})

export default connect(
  null,
  mapDispatchToProps
)(App)
