import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const Severity = ({ score, total }) => {
  const calculateSeverity = () => {
    if (score / total < 0.25) {
      return 'Not Suggestive'
    } else if (score / total < 0.5) {
      return 'Mild'
    } else if (score / total < 0.75) {
      return 'Moderate'
    } else {
      return 'Severe'
    }
  }
  return <Typography variant="subtitle1">{calculateSeverity()}</Typography>
}

Severity.propTypes = {
  score: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}

export default Severity
