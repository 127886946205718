import React from 'react'
import PropTypes from 'prop-types'
import Completed from './Completed'
import Introduction from './Introduction'
import Disclosure from './Disclosure'
import Directions from './Directions'
import Results from './Results'
import Profile from './Profile'
import Gender from '../Gender'
import Age from '../Age'
import Email from '../Email'
import TestQuestion from './TestQuestion'

/*const steps = [
  {
    step: 0,
    name: 'introduction',
  },
  {
    step: 1,
    name: 'disclosure',
  },
  {
    step: 2,
    name: 'profile',
  },
  {
    step: 3,
    name: 'gender',
  },
  {
    step: 4,
    name: 'age',
  },
  {
    step: 5,
    name: 'directions',
  },
  {
    step: 6,
    name: 'survey',
  },
  {
    step: 7,
    name: 'completed',
  },
  {
    step: 8,
    name: 'email',
  },
  {
    step: 9,
    name: 'results',
  },
]*/

class SubjectiveTest extends React.Component {
  state = {
    currentStep: 0,
  }
  handleIntroductionClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleDisclosureClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleProfileClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleGenderClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleAgeClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleDirectionsClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleFinishClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleEmailClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleSeeResultsClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleReturnToHomeClick = () => {
    this.setState({
      currentStep: 0,
    })
    this.props.onReturnHomeClick()
  }
  render() {
    const { currentStep } = this.state

    if (currentStep === 0) {
      return <Introduction onClick={this.handleIntroductionClick} />
    } else if (currentStep === 1) {
      return <Disclosure onClick={this.handleDisclosureClick} />
    } else if (currentStep === 2) {
      return <Profile onClick={this.handleProfileClick} />
    } else if (currentStep === 3) {
      return <Gender onClick={this.handleGenderClick} />
    } else if (currentStep === 4) {
      return <Age onClick={this.handleAgeClick} />
    } else if (currentStep === 5) {
      return <Directions onClick={this.handleDirectionsClick} />
    } else if (currentStep === 6) {
      // show survey
      return <TestQuestion onFinishClick={this.handleFinishClick} />
    } else if (currentStep === 7) {
      return <Completed onClick={this.handleSeeResultsClick} />
    } else if (currentStep === 8) {
      return <Email onClick={this.handleEmailClick} />
    } else if (currentStep === 9) {
      return <Results onClick={this.handleReturnToHomeClick} />
    }
  }
}

SubjectiveTest.propTypes = {
  onReturnHomeClick: PropTypes.func.isRequired,
}

export default SubjectiveTest
