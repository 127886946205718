import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import AnswerRadioGroup from './AnswerRadioGroup'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { answerQuestion } from '../redux/actions/subjectiveTest'
import { connect } from 'react-redux'
import { format } from '../formatters'

const styles = {
  text: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  header: {
    padding: '8px 0',
    color: '#fff',
  },
  body: {
    padding: '8px 0',
    color: '#fff',
  },
}

class Question extends Component {
  state = {
    sectionNumber: -1,
    questionNumber: -1,
    newSection: true,
  }

  handleChange = value => {
    const { answerQuestion, sectionNumber, questionNumber } = this.props
    answerQuestion(sectionNumber, questionNumber, value)
  }
  static getDerivedStateFromProps(props, state) {
    return {
      sectionNumber: props.sectionNumber,
      questionNumber: props.questionNumber,
      newSection: props.sectionNumber !== state.sectionNumber,
    }
  }

  getText = () => {
    const {
      childExample,
      childQuestion,
      example,
      parentExample,
      parentQuestion,
      profile,
      question,
      teacherExample,
      teacherQuestion,
    } = this.props
    let exampleToUse, questionToUse
    if (profile === 'child') {
      exampleToUse = childExample
      questionToUse = childQuestion
    } else if (profile === 'parent') {
      exampleToUse = parentExample
      questionToUse = parentQuestion
    } else {
      exampleToUse = teacherExample
      questionToUse = teacherQuestion
    }
    return {
      example: example && format(example, exampleToUse),
      question: format(question, questionToUse),
    }
  }

  render() {
    const { classes, section, type, value } = this.props

    const { example, question } = this.getText()

    const { newSection, questionNumber, sectionNumber } = this.state
    return (
      <div className={classes.text}>
        <ReactCSSTransitionGroup
          key={sectionNumber}
          transitionName="section"
          transitionAppear={true}
          transitionAppearTimeout={400}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            <Typography variant={'subtitle2'} className={classes.header}>
              Section {sectionNumber + 1}
            </Typography>
            <Typography variant={'body1'} className={classes.body}>
              {section}
            </Typography>
          </div>
        </ReactCSSTransitionGroup>

        <ReactCSSTransitionGroup
          key={sectionNumber + '_' + questionNumber}
          transitionName={newSection ? 'new-section-question' : 'question'}
          transitionAppear={true}
          transitionAppearTimeout={newSection ? 800 : 400}
          transitionEnter={false}
          transitionLeave={false}>
          <div>
            <Typography variant={'subtitle2'} className={classes.header}>
              Question {questionNumber + 1}
            </Typography>
            <Typography variant={'body1'} className={classes.body}>
              {question}
            </Typography>
            <Typography variant={'body1'} className={classes.body}>
              {example}
            </Typography>
            <AnswerRadioGroup type={type} onChange={this.handleChange} selectedValue={value} />
          </div>
        </ReactCSSTransitionGroup>
      </div>
    )
  }
}

Question.propTypes = {
  childExample: PropTypes.array,
  childQuestion: PropTypes.array,
  classes: PropTypes.object.isRequired,
  example: PropTypes.string,
  parentExample: PropTypes.array,
  parentQuestion: PropTypes.array,
  question: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  teacherExample: PropTypes.array,
  teacherQuestion: PropTypes.array,
  section: PropTypes.string.isRequired,
  sectionNumber: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
})

const mapDispatchToProps = dispatch => ({
  answerQuestion: (sectionNumber, questionNumber, value) =>
    dispatch(answerQuestion(sectionNumber, questionNumber, value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Question))
