export const clickScreen = timestamp => ({
  type: 'CLICK_SCREEN',
  timestamp,
})

export const displayImage = (shape, timestamp) => ({
  type: 'DISPLAY_IMAGE',
  shape,
  timestamp,
})

export const startObjectiveTest = timestamp => ({
  type: 'START_OBJECTIVE_TEST',
  timestamp,
})
