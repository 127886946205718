export const frequencyRadioGroup = {
  name: 'frequency',
  items: [
    {
      value: 'never',
      label: 'Never',
    },
    {
      value: 'sometimes',
      label: 'Sometimes',
    },
    {
      value: 'often',
      label: 'Often',
    },
    {
      value: 'mostOfTheTime',
      label: 'Most of the time',
    },
  ],
}

export const subjectsRadioGroup = {
  name: 'subjects',
  items: [
    {
      value: 'no',
      label: 'No',
    },
    {
      value: 'math',
      label: 'Math based (Math/Chemistry/Physics)',
    },
    {
      value: 'reading',
      label: 'Reading',
    },
    {
      value: 'writing',
      label: 'Writing',
    },
    {
      value: 'speech',
      label: 'Speech Based',
    },
    {
      value: 'otherSubject',
      label: 'Other Subject',
    },
  ],
}

export const yesNoRadioGroup = {
  name: 'yesNo',
  items: [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ],
}

export const genderRadioGroup = {
  name: 'gender',
  items: [
    {
      value: 'male',
      label: 'Male',
    },
    {
      value: 'female',
      label: 'Female',
    },
  ],
}

export const profileRadioGroup = {
  name: 'profile',
  items: [
    {
      value: 'child',
      label: 'Child',
    },
    {
      value: 'parent',
      label: 'Parent',
    },
    {
      value: 'teacher',
      label: 'Teacher',
    },
  ],
}
