import React from 'react'
import PropTypes from 'prop-types'
import SurveyFooter from '../../components/SurveyFooter'
import { Button } from '@material-ui/core'
import SurveyPage from '../../components/SurveyPage'
import PageHeader from '../../components/PageHeader'
import { Translate } from 'react-localize-redux'

const t = id => <Translate id={`components.subjectiveTest.disclosure.${id}`} />

const Disclosure = ({ onClick }) => {
  return (
    <SurveyPage>
      <PageHeader header={t('title')} body={t('content')} />
      <SurveyFooter>
        <Button variant="contained" color="primary" size="large" onClick={onClick} fullWidth>
          {t('continue')}
        </Button>
      </SurveyFooter>
    </SurveyPage>
  )
}

Disclosure.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default Disclosure
