import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import LinearDeterminate from '../../components/progress/LinearDeterminate'
import Question from '../../components/Question'
import SurveyFooter from '../../components/SurveyFooter'
import SurveyPage from '../../components/SurveyPage'
import { goToNextQuestion, goToPreviousQuestion } from '../../redux/actions/subjectiveTest'
import { visibleSectionsSelector } from '../../selectors/subjectiveTest'
import { Translate } from 'react-localize-redux'

const t = id => <Translate id={`components.subjectiveTest.test.${id}`} />

const styles = {
  buttonContainer: {
    display: 'flex',
  },
  leftButton: {
    marginRight: 12,
  },
  rightButton: {
    marginLeft: 12,
  },
}

class TestQuestion extends Component {
  state = {
    currentQuestion: 0,
    currentSection: 0,
  }

  handlePreviousClick = () => {
    // move to the previous question in the section
    if (this.state.currentQuestion) {
      this.setState({
        currentQuestion: this.state.currentQuestion - 1,
      })
    } else {
      // move to the previous section
      if (!this.state.currentQuestion) {
        this.setState({
          currentSection: this.state.currentSection - 1,
          currentQuestion: this.props.sections[this.state.currentSection - 1].questions.length - 1,
        })
      }
    }
    this.props.goToPreviousQuestion()
  }

  handleNextClick = () => {
    const section = this.props.sections[this.state.currentSection]
    // move to next question in the section
    if (this.state.currentQuestion < section.questions.length - 1) {
      this.setState({
        currentQuestion: this.state.currentQuestion + 1,
      })
    } else {
      // move to the next section
      if (this.state.currentSection < this.props.sections.length - 1) {
        this.setState({
          currentSection: this.state.currentSection + 1,
          currentQuestion: 0,
        })
      }
    }
    this.props.goToNextQuestion()
  }

  handleFinishClick = () => {
    this.setState({
      currentQuestion: 0,
      currentSection: 0,
    })
    this.props.goToNextQuestion()
    this.props.onFinishClick()
  }

  render() {
    const { classes, onFinishClick, sections } = this.props

    const { currentQuestion, currentSection } = this.state

    const section = sections[currentSection]
    const { questions } = section

    const previousButton = (
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={this.handlePreviousClick}
        fullWidth
        disabled={!currentQuestion && !currentSection}
        className={classes.leftButton}>
        {t('previous')}
      </Button>
    )

    return (
      <SurveyPage>
        <LinearDeterminate />
        <Question
          {...questions[currentQuestion]}
          section={section.name}
          sectionNumber={currentSection}
          questionNumber={currentQuestion}
        />
        {currentQuestion < questions.length - 1 || currentSection < sections.length - 1 ? (
          <SurveyFooter>
            <div className={classes.buttonContainer}>
              {previousButton}
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.handleNextClick}
                fullWidth
                className={classes.rightButton}>
                {t('next')}
              </Button>
            </div>
          </SurveyFooter>
        ) : (
          <SurveyFooter>
            <div className={classes.buttonContainer}>
              {previousButton}
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onFinishClick}
                fullWidth
                className={classes.rightButton}>
                {t('finish')}
              </Button>
            </div>
          </SurveyFooter>
        )}
      </SurveyPage>
    )
  }
}

TestQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  currentQuestion: PropTypes.number,
  currentSection: PropTypes.number,
  onFinishClick: PropTypes.func,
  sections: PropTypes.array,
}

const mapStateToProps = state => ({
  sections: visibleSectionsSelector(state),
})

const mapDispatchToProps = dispatch => ({
  goToNextQuestion: () => dispatch(goToNextQuestion),
  goToPreviousQuestion: () => dispatch(goToPreviousQuestion),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TestQuestion))
