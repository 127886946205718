import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import SurveyFooter from '../components/SurveyFooter'
import SurveyPage from '../components/SurveyPage'
import PageHeader from '../components/PageHeader'
import { submitEmail } from '../redux/actions/profile'
import { Translate } from 'react-localize-redux'
import TextField from '@material-ui/core/TextField'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const t = id => <Translate id={`components.profile.email.${id}`} />

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField)

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  label: {
    color: 'white',
  },
}))

const Email = ({ onClick, submitEmail }) => {
  const classes = useStyles()
  const [values, setValues] = React.useState({
    email: '',
  })
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }
  const handleSubmit = () => {
    submitEmail(values.email)
    onClick()
  }
  return (
    <SurveyPage>
      <PageHeader header={t('title')} body={t('content')}>
        <form className={classes.container} noValidate autoComplete="off">
          <CssTextField
            id="outlined-email"
            label={t('title')}
            value={values.age}
            onChange={handleChange('email')}
            //type="number"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.label,
              },
            }}
            margin="normal"
            variant="outlined"
            inputProps={{ style: { color: 'white' } }}
            /*
            InputProps={{
                classes: {
                  inputColor: classes.inputColor
                }
              }}
              */
          />
        </form>
      </PageHeader>
      <SurveyFooter>
        <Button variant="contained" color="primary" size="large" onClick={handleSubmit} fullWidth>
          {t('continue')}
        </Button>
      </SurveyFooter>
    </SurveyPage>
  )
}

Email.propTypes = {
  onClick: PropTypes.func.isRequired,
  profile: PropTypes.string.isRequired,
  submitEmail: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  submitEmail: email => dispatch(submitEmail(email)),
})

export default connect(
  null,
  mapDispatchToProps
)(Email)
