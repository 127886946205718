import { combineReducers } from 'redux'
import { produce } from 'immer'

const imagesInitialState = []

/*const imagesTestInitialState = [
  {
    shape: 'red',
    timestamp: 1000,
    clickedTimestamp: 2000,
  },
  {
    shape: 'green',
    timestamp: 3000,
    clickedTimestamp: 5000,
  },
  {
    shape: 'red',
    timestamp: 1000 + 15000,
    clickedTimestamp: 1500 + 15000,
  },
  {
    shape: 'green',
    timestamp: 3000 + 15000,
    clickedTimestamp: 5000 + 15000,
  },
  {
    shape: 'red',
    timestamp: 1000 + 30000,
  },
  {
    shape: 'green',
    timestamp: 3000 + 30000,
  },
  {
    shape: 'red',
    timestamp: 1000 + 45000,
  },
  {
    shape: 'green',
    timestamp: 3000 + 45000,
  },
]*/

const initialState = {
  startTimestamp: 0,
  testLength: 30 * 1000, // @todo 30 seconds to test
  startDelay: 2000,
}

const images = produce((draft = imagesInitialState, action) => {
  switch (action.type) {
    case 'DISPLAY_IMAGE':
      draft.push({
        shape: action.shape,
        shownTimestamp: action.timestamp,
      })
      break
    case 'CLICK_SCREEN':
      draft[draft.length - 1].clickedTimestamp = action.timestamp
      break
    default:
      return draft
  }
})

const data = (state = initialState, action) => {
  switch (action.type) {
    case 'START_OBJECTIVE_TEST':
      return {
        ...state,
        startTimestamp: action.timestamp,
      }
    default:
      return state
  }
}

export default combineReducers({
  data,
  images,
})
