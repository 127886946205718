import { combineReducers } from 'redux'
import produce from 'immer'
import inattentiveSymptomQuestions from '../../data/inattentiveSymptomQuestions.json'
import hyperactiveAndImpulseSymptomQuestions from '../../data/hyperactiveAndImpulseSymptomQuestions.json'
import ageOfOnsetCriteriaQuestions from '../../data/ageOfOnsetCriteriaQuestions.json'
import criteriaOfDysfunctionInLifeQuestions from '../../data/criteriaOfDysfunctionInLifeQuestions.json'
import contributingIssuesQuestions from '../../data/contributingIssuesQuestions.json'

export const sectionsInitialState = [
  {
    questions: inattentiveSymptomQuestions,
    name: 'Inattentive Symptoms',
  },
  {
    questions: hyperactiveAndImpulseSymptomQuestions,
    name: 'Hyperactive and Impulsive Symptoms',
  },
  {
    questions: ageOfOnsetCriteriaQuestions,
    name: 'Age Of Onset Criteria',
  },
  {
    questions: criteriaOfDysfunctionInLifeQuestions,
    name: 'Criteria Of Dysfunction In Life',
  },
  {
    questions: contributingIssuesQuestions,
    name: 'Contributing Issues',
  },
]

const sections = produce((draft = sectionsInitialState, action) => {
  switch (action.type) {
    case 'ANSWER_QUESTION':
      draft[action.section].questions[action.question].value = action.value
      break
    default: {
      return draft
    }
  }
})

const questionsAnswered = (state = 0, action) => {
  switch (action.type) {
    case 'GO_TO_NEXT_QUESTION':
      return state + 1
    case 'GO_TO_PREVIOUS_QUESTION':
      return state - 1
    case 'START_SUBJECTIVE_TEST':
      return 0
    default:
      return state
  }
}

export default combineReducers({ sections, questionsAnswered })
