import { combineReducers } from 'redux'

import objectiveTest from './objectiveTest'
import profile from './profile'
import subjectiveTest from './subjectiveTest'
import { localizeReducer } from 'react-localize-redux'

export default combineReducers({
  localize: localizeReducer,
  objectiveTest,
  profile,
  subjectiveTest,
})
