import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SurveyFooter from '../../components/SurveyFooter'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  totalCorrectAnswersSelector,
  totalIncorrectAnswersSelector,
  totalQuestionsSelector,
  totalQuestionsPerQuarterSelector,
  totalCorrectAnswersPerQuarterSelector,
  totalIncorrectAnswersPerQuarterSelector,
  totalOmissionErrorsSelector,
  totalOmissionErrorsPerQuarterSelector,
  totalCommissionErrorsSelector,
  totalCommissionErrorsPerQuarterSelector,
} from '../../selectors/objectiveTest'
import ResultsGrouping from '../../components/results/ResultsGrouping'
import SurveyPage from '../../components/SurveyPage'
import CommissionLineChart from '../../components/results/objectiveTest/CommissionLineChart'
import OmissionLineChart from '../../components/results/objectiveTest/OmissionLineChart'
import ResponseTimeLineChart from '../../components/results/objectiveTest/ResponseTimeLineChart'
import {
  commissionLineChartDataSelector,
  omissionLineChartDataSelector,
  responseTimeLineChartDataSelector,
} from '../../selectors/objectiveTest'
import { Translate } from 'react-localize-redux'

const t = id => <Translate id={`components.objectiveTest.results.${id}`} />

const useStyles = makeStyles({
  content: {
    padding: 24,
    //height: 'calc(100vh - 84px)',
    height: 'calc(var(--vh, 1vh) * 100 - 84px)',
    overflow: 'scroll',
  },
  resultsGroupingContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  resultsText: {
    color: 'white',
  },
})

// @todo what should the threshold's be?
export const Results = ({
  commissionLineChartData,
  omissionLineChartData,
  responseTimeLineChartData,
  onClick,
  totalCorrectAnswers,
  totalIncorrectAnswers,
  totalQuestions,
  totalQuestionsPerQuarter,
  totalCorrectAnswersPerQuarter,
  totalIncorrectAnswersPerQuarter,
  totalOmissionErrors,
  totalOmissionErrorsPerQuarter,
  totalCommissionErrors,
  totalCommissionErrorsPerQuarter,
}) => {
  const classes = useStyles()

  return (
    <SurveyPage>
      <div className={classes.content}>
        <Typography variant="h5" color="primary" className={classes.header}>
          {t('title')}
        </Typography>
        {/*<Typography className={classes.resultsText}>Example Results</Typography>
        <Typography className={classes.resultsText}>
          Total Images Shown: {totalQuestions}
        </Typography>
        <Typography className={classes.resultsText}>
          Total Correct Answers: {totalCorrectAnswers}
        </Typography>
        <Typography className={classes.resultsText}>
          Total Incorrect Answers: {totalIncorrectAnswers}
        </Typography>
        <Typography className={classes.resultsText}>
          Total Images Shown Per Quarter: {totalQuestionsPerQuarter.toString()}
        </Typography>
        <Typography className={classes.resultsText}>
          Total Correct Answers Per Quarter: {totalCorrectAnswersPerQuarter.toString()}
        </Typography>
        <Typography className={classes.resultsText}>
          Total Incorrect Answers Per Quarter: {totalIncorrectAnswersPerQuarter.toString()}
  </Typography>
  <Typography className={classes.resultsText}>Total Wrong Answers</Typography>*/}
        <div className={classes.resultsGroupingContainer}>
          <ResultsGrouping
            total={totalQuestions}
            score={totalIncorrectAnswers}
            title={t('totalWrongAnswers')}
            threshold={totalQuestions / 4}
          />
          {[...Array(4).keys()].map(i => (
            <ResultsGrouping
              key={i}
              total={totalQuestionsPerQuarter[i]}
              score={totalIncorrectAnswersPerQuarter[i]}
              title={t(`totalWrongAnswersQuarter${i + 1}`)}
              threshold={totalQuestionsPerQuarter[i] / 4}
            />
          ))}
        </div>
        {/*<Typography className={classes.resultsText}>
          Total Omission Errors: {totalOmissionErrors.toString()}
        </Typography>
        <Typography className={classes.resultsText}>
          Total Omission Errors Per Quarter: {totalOmissionErrorsPerQuarter.toString()}
        </Typography>
          <Typography className={classes.resultsText}>Total Omission Errors</Typography>*/}
        <div className={classes.resultsGroupingContainer}>
          <ResultsGrouping
            total={totalQuestions}
            score={totalOmissionErrors}
            title={t('totalOmissionErrors')}
            threshold={totalQuestions / 4}
          />
          {[...Array(4).keys()].map(i => (
            <ResultsGrouping
              key={i}
              total={totalQuestionsPerQuarter[i]}
              score={totalOmissionErrorsPerQuarter[i]}
              title={t(`totalOmissionErrorsQuarter${i + 1}`)}
              threshold={totalQuestionsPerQuarter[i] / 4}
            />
          ))}
        </div>
        {/*<Typography className={classes.resultsText}>
          Total Commission Errors: {totalCommissionErrors.toString()}
        </Typography>
        <Typography className={classes.resultsText}>
          Total Commission Errors Per Quarter: {totalCommissionErrorsPerQuarter.toString()}
        </Typography>
          <Typography className={classes.resultsText}>Total Commission Errors</Typography>*/}
        <div className={classes.resultsGroupingContainer}>
          <ResultsGrouping
            total={totalQuestions}
            score={totalCommissionErrors}
            title={t('totalCommissionErrors')}
            threshold={totalQuestions / 4}
          />
          {[...Array(4).keys()].map(i => (
            <ResultsGrouping
              key={i}
              total={totalQuestionsPerQuarter[i]}
              score={totalCommissionErrorsPerQuarter[i]}
              title={t(`totalCommissionErrorsQuarter${i + 1}`)}
              threshold={totalQuestions / 4}
            />
          ))}
        </div>
        <div className={classes.resultsGroupingContainer}>
          <CommissionLineChart commissionLineChartData={commissionLineChartData} />
          <OmissionLineChart omissionLineChartData={omissionLineChartData} />
          <ResponseTimeLineChart responseTimeLineChartData={responseTimeLineChartData} />
        </div>
      </div>
      <SurveyFooter>
        <Button variant="contained" color="primary" size="large" onClick={onClick} fullWidth>
          {t('continue')}
        </Button>
      </SurveyFooter>
    </SurveyPage>
  )
}

Results.propTypes = {
  commissionLineChartData: PropTypes.array.isRequired,
  omissionLineChartData: PropTypes.array.isRequired,
  responseTimeLineChartData: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  totalCorrectAnswers: PropTypes.number,
  totalIncorrectAnswers: PropTypes.number,
  totalQuestions: PropTypes.number,
  totalQuestionsPerQuarter: PropTypes.array,
  totalCorrectAnswersPerQuarter: PropTypes.array,
  totalIncorrectAnswersPerQuarter: PropTypes.array,
  totalOmissionErrors: PropTypes.number,
  totalOmissionErrorsPerQuarter: PropTypes.array,
  totalCommissionErrors: PropTypes.number,
  totalCommissionErrorsPerQuarter: PropTypes.array,
}

const mapStateToProps = state => ({
  commissionLineChartData: commissionLineChartDataSelector(state),
  omissionLineChartData: omissionLineChartDataSelector(state),
  responseTimeLineChartData: responseTimeLineChartDataSelector(state),
  totalCorrectAnswers: totalCorrectAnswersSelector(state),
  totalIncorrectAnswers: totalIncorrectAnswersSelector(state),
  totalQuestions: totalQuestionsSelector(state),
  totalQuestionsPerQuarter: totalQuestionsPerQuarterSelector(state),
  totalCorrectAnswersPerQuarter: totalCorrectAnswersPerQuarterSelector(state),
  totalIncorrectAnswersPerQuarter: totalIncorrectAnswersPerQuarterSelector(state),
  totalOmissionErrors: totalOmissionErrorsSelector(state),
  totalOmissionErrorsPerQuarter: totalOmissionErrorsPerQuarterSelector(state),
  totalCommissionErrors: totalCommissionErrorsSelector(state),
  totalCommissionErrorsPerQuarter: totalCommissionErrorsPerQuarterSelector(state),
})

export default connect(
  mapStateToProps,
  null
)(Results)
