import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Completed from './Completed'
import Introduction from './Introduction'
import Disclosure from './Disclosure'
import Directions from './Directions'
import Results from './Results'
import TimedTest from './TimedTest'
import Gender from '../Gender'
import Age from '../Age'
import Email from '../Email'
import { startObjectiveTest } from '../../redux/actions/objectiveTest'

/*const steps = [
  {
    step: 0,
    name: 'introduction',
  },
  {
    step: 1,
    name: 'disclosure',
  },
  {
    step: 2,
    name: 'gender',
  },
  {
    step: 3,
    name: 'age',
  },
  {
    step: 4,
    name: 'directions',
  },
  {
    step: 5,
    name: 'survey',
  },
  {
    step: 6,
    name: 'completed',
  },
  {
    step: 7,
    name: 'results',
  },
]*/

class ObjectiveTest extends React.Component {
  state = {
    currentStep: 0,
  }
  handleIntroductionClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleDisclosureClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleGenderClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleAgeClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleDirectionsClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
    this.props.startObjectiveTest(Date.now())
  }
  handleFinishClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleSeeResultsClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleEmailClick = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    })
  }
  handleReturnToHomeClick = () => {
    this.setState({
      currentStep: 0,
    })
    this.props.onReturnHomeClick()
  }
  render() {
    const { currentStep } = this.state

    if (currentStep === 0) {
      return <Introduction onClick={this.handleIntroductionClick} />
    } else if (currentStep === 1) {
      return <Disclosure onClick={this.handleDisclosureClick} />
    } else if (currentStep === 2) {
      return <Gender onClick={this.handleGenderClick} />
    } else if (currentStep === 3) {
      return <Age onClick={this.handleAgeClick} />
    } else if (currentStep === 4) {
      return <Directions onClick={this.handleDirectionsClick} />
    } else if (currentStep === 5) {
      // show test
      return <TimedTest onCompleted={this.handleFinishClick} />
    } else if (currentStep === 6) {
      return <Completed onClick={this.handleSeeResultsClick} />
    } else if (currentStep === 7) {
      return <Email onClick={this.handleEmailClick} />
    } else if (currentStep === 8) {
      return <Results onClick={this.handleReturnToHomeClick} />
    }
  }
}

ObjectiveTest.propTypes = {
  onReturnHomeClick: PropTypes.func.isRequired,
  startObjectiveTest: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  startObjectiveTest: timestamp => dispatch(startObjectiveTest(timestamp)),
})

export default connect(
  null,
  mapDispatchToProps
)(ObjectiveTest)
